import './PreHome.css';
import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useStore } from '../StoreContext';


const PreHome = (props) => {
    const {searchText, setSearchText, startSearch, setStartSearch, isClearOn, setIsClearOn } = useStore();
    const title = 'O2E Digital Journey-Landing Page';

    //localStorage.removeItem("SearcOpp");
    localStorage.removeItem("OppId");
    //const userName = localStorage.getItem("userName")

    useEffect(() => {
        document.title = title;
      }, [title]);


    const opportunityListHandler = () => {
        setSearchText("");
        setStartSearch(false);
        setIsClearOn(false);
    }

    return (
        <>
            <div className='pre-home-block'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-10 offset-lg-1 col-md-12'>
                            <h1>Welcome back, <span> {props.userName}</span></h1>
                            
                            <div className='option-list-item' style={{ marginBottom: "10px" }}>
                                <h2>
                                    Click "Yes" to do Pursuit Qualification assessment <br />& check probability of winning
                                </h2>
                                <a href='https://indigital.lightning.force.com/lightning/n/Pursuit_Qualification' target='_blank' className='btn btn-bg'><span>Yes</span> <i className="demo-icon icon-arrow_right_special">&#x2b;</i></a>
                            </div>
                            <div className='or-circle'>
                                <div></div>
                                <span>OR</span>
                            </div>
                            <div className='option-list-item'>
                                <h2>
                                    Do you want to create a new <br /> opportunity in Jupiter?
                                </h2>
                                <a href='https://deloitte.lightning.force.com/lightning/o/Opportunity/list?filterName=Recent' target='_blank' className='btn btn-bg'><span>Yes</span> <i aria-hidden="true"  className="demo-icon icon-arrow_right_special">&#x2b;</i></a>
                            </div>
                            <div className='or-circle'>
                                <div></div>
                                <span>OR</span>
                            </div>
                            <div className='option-list-item'>
                                <h2>
                                    Do you want to check status of <br /> existing opportunity in O2E Flow?
                                </h2>
                                <div onClick={() => opportunityListHandler()}>
                                    <a href='/oppurtunity-list' className='btn border-btn'>Check Opportunity Status <i aria-hidden="true" className="demo-icon icon-arrow_right_special">&#x2b;</i></a>
                                </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default PreHome;