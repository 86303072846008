import './OppurtunityItem.css';
import React, { useRef } from 'react';
import { GithubContext } from '../context/context';
import { Link, Redirect } from 'react-router-dom';
import { useState, useEffect } from 'react';
const OppurtunityItem = () => {


    const [isLoading, setIsLoading] = useState(true);
    const { isDesktop } = React.useContext(GithubContext);
    const [showMore, setShowMore] = useState(false);
    const [showJupiter, setShowJupiter] = useState(false);
    const [showDRMS, setShowDRMS] = useState(false);
    const [showIRMS, setShowIRMS] = useState(false);
    const [showPMS, setShowPMS] = useState(false);
    const [showECM, setShowECM] = useState(false);
    const [showSAP, setShowSAP] = useState(false);
    const [showDRB, setShowDRB] = useState(false);
    const [showEDS, setShowEDS] = useState(false);
    const [showCRP, setShowCRP] = useState(false);

    //Modal Focus for accessibility features
    const jupiterBoxRef = useRef(null);
    const irmsBoxRef = useRef(null);  
    const drmsBoxRef = useRef(null);
    const p2wBoxRef = useRef(null);
    const drbBoxRef = useRef(null);
    const ecmBoxRef = useRef(null);
    const crpBoxRef = useRef(null);
    const sapBoxRef = useRef(null);
    const edsBoxRef = useRef(null);

    const edsModalRef = useRef(null);
    const crpModalRef = useRef(null);
    const sapModalRef = useRef(null);
    const ecmModalRef = useRef(null);

    const crpMoreRef = useRef(null);
    const ecmMoreRef = useRef(null);
    const sapMoreRef = useRef(null);

    const jupiterHandler = (e) => {
        e.preventDefault();
        setShowJupiter(!showJupiter);
        setShowDRMS(false)
        setShowIRMS(false)
        setShowPMS(false)
        setShowECM(false)
        setShowSAP(false)
        setShowDRB(false)
        setShowEDS(false)
        setShowCRP(false)
    }
    const DRMSHandler = (e) => {
        e.preventDefault();
        setShowJupiter(false);
        setShowDRMS(!showDRMS)
        setShowIRMS(false)
        setShowPMS(false)
        setShowECM(false)
        setShowSAP(false)
        setShowDRB(false)
        setShowEDS(false)
        setShowCRP(false)
    }
    const IRMSHandler = (e) => {
        e.preventDefault();
        setShowJupiter(false);
        setShowDRMS(false)
        setShowIRMS(!showIRMS)
        setShowPMS(false)
        setShowECM(false)
        setShowSAP(false)
        setShowDRB(false)
        setShowEDS(false)
        setShowCRP(false)
    }
    const PMSHandler = (e) => {
        e.preventDefault();
        setShowJupiter(false);
        setShowDRMS(false)
        setShowIRMS(false)
        setShowPMS(!showPMS)
        setShowECM(false)
        setShowSAP(false)
        setShowDRB(false)
        setShowEDS(false)
        setShowCRP(false)
    }
    const DRBHandler = (e) => {
        e.preventDefault();
        setShowJupiter(false);
        setShowDRMS(false)
        setShowIRMS(false)
        setShowPMS(false)
        setShowECM(false)
        setShowSAP(false)
        setShowDRB(!showDRB)
        setShowEDS(false)
        setShowCRP(false)
    }
    const ECMHandler = (e) => {
        e.preventDefault();
        setShowJupiter(false);
        setShowDRMS(false)
        setShowIRMS(false)
        setShowPMS(false)
        setShowECM(!showECM)
        setShowSAP(false)
        setShowDRB(false)
        setShowEDS(false)
        setShowCRP(false)
    }
    const SAPHandler = (e) => {
        e.preventDefault();
        setShowJupiter(false);
        setShowDRMS(false)
        setShowIRMS(false)
        setShowPMS(false)
        setShowECM(false)
        setShowSAP(!showSAP)
        setShowDRB(false)
        setShowEDS(false)
        setShowCRP(false)
    }

    const EDSHandler = (e) => {
        e.preventDefault();
        setShowJupiter(false);
        setShowDRMS(false)
        setShowIRMS(false)
        setShowPMS(false)
        setShowECM(false)
        setShowSAP(false)
        setShowDRB(false)
        setShowEDS(!showEDS)
        setShowCRP(false)
    }

    const CRPHandler = (e) => {
        e.preventDefault();
        setShowJupiter(false);
        setShowDRMS(false)
        setShowIRMS(false)
        setShowPMS(false)
        setShowECM(false)
        setShowSAP(false)
        setShowDRB(false)
        setShowEDS(false)
        setShowCRP(!showCRP)
    }
    const handleMoreInfoCRPClick = () => {
        setShowCRP(false);
    };

    const handleMoreInfoEDSClick = () => {
        setShowEDS(false);
    };

    const handleMoreInfoECMClick = () => {
        setShowECM(false);
    };

    const handleMoreInfoSAPClick = () => {
        setShowSAP(false);
    };

    const [Opportunities, setOpportunities] = useState([]);
    const [PmsOpportunities, setPmsOpportunities] = useState([]);
    const [SapOpportunities, setSapOpportunities] = useState([]);
    const [pmsBusiness, setpmsBusiness] = useState(null)
    const [pmsOppType, setpmsOppType] = useState(null)
    const [JupiterCountry, setJupiterCountry] = useState(null)
    const [irmsOpportunities, setirmsOpportunities] = useState([])
    const [drmsOpportunities, setdrmsOpportunities] = useState([])
    const [ecmOpportunities, setecmOpportunities] = useState([])
    const [drbOpportunities, setdrbOpportunities] = useState([])
    const [edsOpportunities, setedsOpportunities] = useState([])
    const [crpOpportunities, setcrpOpportunities] = useState([])
    const [EDS3Records, setEDS3Records] = useState([]);
    const [CRP5Records, setCRP5Records] = useState([]);
    //var MainOppId = localStorage.getItem("OppId");
    const [MainOppId, setMainOppId] = useState();
    const [SAP2Records, setSAP2Records] = useState([]);
    const [ECM2Records, setECM2Records] = useState([]);

    const crpCloseModal = () => {
        crpMoreRef.current.focus();
    };
    const ecmCloseModal = () => {
        ecmMoreRef.current.focus();
    };
    const sapCloseModal = () => {
        sapMoreRef.current.focus();
    };


    useEffect(() => {
        if (showJupiter || showIRMS || showPMS || showDRMS || showDRB || showECM || showSAP || showCRP || showEDS) {
            let focusableElements;          
            if (showJupiter) {
                focusableElements = jupiterBoxRef.current.querySelectorAll('a, button, input, textarea, select');
            } 
            if (showIRMS) {
                focusableElements = irmsBoxRef.current.querySelectorAll('a, button, input, textarea, select');
            }  
            if (showDRMS) {
                focusableElements = drmsBoxRef.current.querySelectorAll('a, button, input, textarea, select');
            }  
            if (showPMS) {
                focusableElements = p2wBoxRef.current.querySelectorAll('a, button, input, textarea, select');
            }  
            if (showDRB) {
                focusableElements = drbBoxRef.current.querySelectorAll('a, button, input, textarea, select');
            }  
            if (showECM) {
                focusableElements = ecmBoxRef.current.querySelectorAll('a, button, input, textarea, select');
            } 
            if (showSAP) {
                focusableElements = sapBoxRef.current.querySelectorAll('a, button, input, textarea, select');
            }  
            if (showCRP) {
                focusableElements = crpBoxRef.current.querySelectorAll('a, button, input, textarea, select');
            } 
            if (showEDS) {
                focusableElements = edsBoxRef.current.querySelectorAll('a, button, input, textarea, select');
            }   
          const firstFocusableElement = focusableElements[0];
          const lastFocusableElement = focusableElements[focusableElements.length - 1];
    
          const handleTabKey = (e) => {
            if (e.key === 'Tab') {
              if (e.shiftKey) {
                if (document.activeElement === firstFocusableElement) {
                  e.preventDefault();
                  lastFocusableElement.focus();
                }
              } else {
                if (document.activeElement === lastFocusableElement) {
                  e.preventDefault();
                  firstFocusableElement.focus();
                }
              }
            }
          }; 
          firstFocusableElement.focus(); 
          document.addEventListener('keydown', handleTabKey); 
          return () => {
            document.removeEventListener('keydown', handleTabKey);
          };
        }
    }, [showJupiter, showIRMS, showDRMS, showPMS, showDRB, showECM, showSAP, showCRP, showEDS]);
    
    const trapFocus = (modalRef) => {
        const focusableElements = modalRef.current.querySelectorAll('a, button, input, textarea, select');
        const firstFocusableElement = focusableElements[0];
        const lastFocusableElement = focusableElements[focusableElements.length - 1];
      
        const handleTabKey = (e) => {
          if (e.key === 'Tab') {
            if (e.shiftKey) {
              if (document.activeElement === firstFocusableElement) {
                e.preventDefault();
                lastFocusableElement.focus();
              }
            } else {
              if (document.activeElement === lastFocusableElement) {
                e.preventDefault();
                firstFocusableElement.focus();
              }
            }
          }
        };
      
        document.addEventListener('keydown', handleTabKey);
      
        return () => {
          document.removeEventListener('keydown', handleTabKey);
        };
      };
    useEffect(() => {
        let cleanupFn;
        
        const handleModalFocus = () => {
          if (document.querySelector('#moreEDSInfoModal.show')) {
            cleanupFn = trapFocus(edsModalRef);
          } else if (document.querySelector('#moreCRPInfoModal.show')) {
            cleanupFn = trapFocus(crpModalRef);
          } else if (document.querySelector('#moreSAPInfoModal.show')) {
            cleanupFn = trapFocus(sapModalRef);
          }
          else if (document.querySelector('#moreECMInfoModal.show')) {
            cleanupFn = trapFocus(ecmModalRef);
          }
        };
    
        handleModalFocus();  
        const observer = new MutationObserver(handleModalFocus);
        observer.observe(document.body, { attributes: true, subtree: true, attributeFilter: ['class'] });
    
        return () => {
          if (cleanupFn) cleanupFn();
          observer.disconnect();
        };
      }, []);


    useEffect(() => {
        setMainOppId(localStorage.getItem("OppId"))
    }, [])

    const fetchOpportunities = () => {
        setIsLoading(true);

        fetch('jupiterdata/GetOppDetails/' + localStorage.getItem("OppId")).then(response => {
            return response.json();
        }).then(data => {

            if (data.length > 0) {
                debugger;
                console.log(data);

                setOpportunities(data[0].opportunityDetail);
                setPmsOpportunities(data[0].pmsData);
                setSapOpportunities(data[0].sAPData);
                if (data[0].opportunityDetail.length > 0) {
                    setpmsOppType(data[0].opportunityDetail[0].opportunityType)
                    setJupiterCountry(data[0].opportunityDetail[0].country)
                }

                if (data[0].pmsData.length > 0) {
                    setpmsBusiness(data[0].pmsData[0].business)
                }
                setirmsOpportunities(data[0].irMSData)
                setdrmsOpportunities(data[0].dRMSData)
                setecmOpportunities(data[0].eCMData)
                setdrbOpportunities(data[0].dRBData)
                setedsOpportunities(data[0].eDSData)
                setcrpOpportunities(data[0].cRPData)
                if (data[0].eDSData.length > 0) {
                    if (data[0].eDSData.length > 3) {
                        setEDS3Records(data[0].eDSData.slice(0, 3));
                    }
                    else {
                        setEDS3Records(data[0].eDSData);
                    }
                }
                             

                if (data[0].cRPData.length > 0) {
                    if (data[0].cRPData.length > 3) {
                        setCRP5Records(data[0].cRPData.slice(0, 3));
                    }
                    else {
                        setCRP5Records(data[0].cRPData);
                    }
                }

                if (data[0].sAPData.length > 0) {
                    if (data[0].sAPData.length > 2) {
                        setSAP2Records(data[0].sAPData.slice(0, 2));
                    }
                    else {
                        setSAP2Records(data[0].sAPData);
                    }
                }

                if (data[0].eCMData.length > 0) {
                    if (data[0].eCMData.length > 2) {
                        setECM2Records(data[0].eCMData.slice(0, 2));
                    }
                    else {
                        setECM2Records(data[0].eCMData);
                    }
                }


            }
            setIsLoading(false);
        }).catch(function (err) {
            console.log('SOMETHING WENT WRONG');
            setIsLoading(false);
        });
    }
    useEffect(() => {
        fetchOpportunities()
    }, [])

    useEffect(() => {
        if (isDesktop) {
            setShowMore(true)
        }
    }, [isDesktop])



    const left = (window.innerWidth - 600) / 2;
    const top = (window.innerWidth - 400) / 2;
    const url = `https://inrrohome.deloitte.com/sites/IRMS/SitePages/iRMSO2ESearch.aspx?oppid=${MainOppId}&type=IRMS`
    const redirectHandler = (e) => {
        e.preventDefault()
        window.open(`${url}`, "_blank", `width=${600} height=${600} left=${left} top=${top}`, `toolbar=no`)
    }


    return (
        <>
            {/* Opportunity Header Part Information */}
            <section className="oppurtunity-information">
                {Opportunities.map((Opp, i) => (
                    <div key={i} className="oppurtunity-list">
                        <div className="search-card-icon">
                            <Link to="/oppurtunity-list">
                                <img src="./image/icons/back.svg" alt="Back to dashboard" />
                            </Link>
                        </div>
                        <h1 style={{position: 'absolute', opacity: '0', zIndex: '-1'}} className="oppurtunity-item-value">{Opp.client_Name}</h1>
                        <div className="oppurtunity-item mob-oppurtunity-item account-label">
                            
                            <h2 className="oppurtunity-item-label">Account Name:</h2>
                            <div className="oppurtunity-item-value">{Opp.client_Name}</div>
                            
                        </div>

                        {showMore &&
                            <>
                                <div className="oppurtunity-item oppurtunity-id-col mob-oppurtunity-item">
                                    <h2 className="oppurtunity-item-label">Opportunity ID | Type: </h2>
                                    <div className="oppurtunity-item-value">{Opp.opportunityId} | {pmsOppType}</div>
                                </div>
                                <div className="oppurtunity-item mob-oppurtunity-item">
                                    <h2 className="oppurtunity-item-label">Opportunity Name : </h2>
                                    <div className="oppurtunity-item-value">{Opp.engagement_Name}</div>
                                </div>
                            </>
                        }
                        {!isDesktop &&
                            <div className='showMore' onClick={() => setShowMore(!showMore)}> {showMore ? <> Show Less <i className="demo-icon icon-arrow_up_special">&#x2d;</i> </> : <span>Show More <i className="demo-icon icon-arrow_down_special">&#x28;</i></span>}</div>
                        }

                    </div>
                ))}
            </section>

            <div className="flow-chart-block">
                <div className="chart-box-list">
                    <div className="chart-box oppurtunity-created">
                        <div className="chart-box-info">
                            <h3>O2E Process <br /> Started</h3>
                            <span className="icon-left"><i aria-hidden='true' className="demo-icon icon-file_edit_special">&#x67;</i></span>
                        </div>
                        <div className="arrow-line"></div>
                    </div>

                    {/* Jupiter Data*/}
                    {Opportunities.length > 0 ?
                        Opportunities ? Opportunities.map((Opp, index) => (
                            <div key={index} className={Opp.jupiterStatus == 1 ? "chart-box completed-status jupiter-box" : Opp.jupiterStatus == 2 ? "chart-box progress-status jupiter-box" : Opp.jupiterStatus == 3 ? "chart-box not-started-status jupiter-box" : Opp.jupiterStatus == 4 ? "chart-box not-required-status jupiter-box" : Opp.jupiterStatus == 5 ? "chart-box rejected-status jupiter-box" : Opp.jupiterStatus == 6 ? "chart-box closed-status jupiter-box" : "chart-box not-started-status jupiter-box"} >
                                <p style={{position: 'absolute', opacity: '0'}}>Jupiter Status:
                                {Opp.jupiterStatus == 1 ? "Completed" : Opp.jupiterStatus == 2 ? "In Progress" : Opp.jupiterStatus == 3 ? "Not Started" : Opp.jupiterStatus == 4 ? "Not Required" : Opp.jupiterStatus == 5 ? "Rejected" : Opp.jupiterStatus == 6 ? "Closed" : "Not Started"}
                                </p>
                                <div className="chart-box-info">
                                
                                    <div className='title-with-icon'>
                                        <h3>
                                            <span className="icon-left"></span>
                                            Jupiter
                                        </h3>
                                        <span className="icon-right">
                                            <a href='#' onClick={jupiterHandler}><img src="./image/icons/info.svg" alt="Jupiter Details"  /></a>
                                            {showJupiter && <>
                                                <div ref={jupiterBoxRef} tabIndex="-1" aria-modal="true" className='tooltip-info' aria-labelledby="jp" aria-hidden="true">
                                                    <div className='tooltip-header' >
                                                        <h2 id="jp">Jupiter Details</h2>
                                                        <button aria-label="close" type="button" className="close" onClick={jupiterHandler}>×</button>
                                                    </div>
                                                    <ul className='know-more-list' >
                                                        {/*<div className="know-more-item">*/}
                                                        {/*    <div className="label">Type:</div>*/}
                                                        {/*    <div className="label-value">{Opp.opportunitType}</div>*/}
                                                        {/*</div>*/}
                                                        <li className="know-more-item">
                                                            <div className="label">Sales Stage:</div>
                                                            <div className="label-value">{Opp.saleStage}</div>
                                                        </li>
                                                        <li className="know-more-item">
                                                            <div className="label">Expected Closure:</div>
                                                            <div className="label-value">{Opp.expectedClosedDate}</div>
                                                        </li>
                                                        <li className="know-more-item">
                                                            <div className="label">Probability %:</div>
                                                            <div className="label-value">{Opp.probability}</div>
                                                        </li>
                                                        <li className="know-more-item">
                                                            <div className="label">Sale Stage Updated On:</div>
                                                            <div className="label-value">{Opp.saleStageUpdatedOn}</div>
                                                        </li>
                                                        <li className="know-more-item">
                                                            <div className="label">Opportunity Age (In Days):</div>
                                                            <div className="label-value">{Opp.opportunityAgeIn}</div>
                                                        </li>
                                                    </ul>


                                                </div>
                                                <div className='tooltip-modal'></div></>
                                            }
                                        </span>
                                    </div>
                                    {Opportunities[0].salesForceId ? <Link target='_blank' to={`https://deloitte.lightning.force.com/lightning/r/Opportunity/${Opportunities[0].salesForceId}/view`} >Go To Jupiter <span><i aria-hidden="true" className="demo-icon icon-external_link_special">&#x61;</i></span></Link> : <Link target='_blank' to={`https://deloitte.lightning.force.com/lightning/o/Opportunity/list?filterName=Recent`}>Go To Jupiter <span><i aria-hidden="true" className="demo-icon icon-external_link_special">&#x61;</i></span></Link>}

                                    {/* <a href="#" data-toggle="modal" data-target="#knowMore">Know More</a> */}
                                </div>
                            </div>
                        )) : <p></p>
                        : <div className="chart-box not-started-status jupiter-box" >
                            <p style={{position: 'absolute', opacity: '0'}}>Jupiter Status: Not Started</p>
                            <div className="chart-box-info">
                                <div className='title-with-icon'>
                                    <h3>
                                        <span className="icon-left"></span>
                                        Jupiter
                                    </h3>      
                                    <span className="icon-right">
                                    <a href='#' onClick={jupiterHandler}><img src="./image/icons/info.svg" alt="Jupiter Details"  /></a>
                                        {showJupiter &&
                                            <>
                                                <div className='tooltip-info' aria-labelledby="jp">
                                                    <div className='tooltip-header'>
                                                        <h2 id="jp">Jupiter Details</h2>
                                                        <button aria-label="close" type="button" className="close" onClick={jupiterHandler}>×</button>
                                                    </div>
                                                    <div className='know-more-list' >
                                                        No Data Found
                                                    </div>
                                                </div>
                                                <div className='tooltip-modal'></div></>
                                        }
                                    </span>                              
                                </div>
                                <Link target='_blank' to={`https://deloitte.lightning.force.com/lightning/o/Opportunity/list?filterName=Recent`}>Go To Jupiter <span><i aria-hidden='true' className="demo-icon  icon-external_link_special">&#x61;</i></span></Link>
                            </div>
                        </div>}

                    <div className="chart-box level-3">
                        <div role='group' className="chart-box-inner-list">
                            <p style={{position: 'absolute', opacity: '0', zIndex: '-1'}}>Group of IRMS, DRMS, P2W & DRB Block</p>
                            {/* IRMS Data*/}
                            {irmsOpportunities.length > 0 ?
                                irmsOpportunities ? irmsOpportunities.map((Opp, index) => (
                                    <div key={index} className={Opp.irmsStatus == 1 ? "chart-box-info completed-status" : Opp.irmsStatus == 2 ? "chart-box-info progress-status" : Opp.irmsStatus == 3 ? "chart-box-info not-started-status" : Opp.irmsStatus == 4 ? "chart-box-info not-required-status" : Opp.irmsStatus == 5 ? "chart-box-info rejected-status" : Opp.irmsStatus == 6 ? "chart-box-info closed-status" : "chart-box-info not-started-status"}  >
                                        <p style={{position: 'absolute', opacity: '0'}}>IRMS Status:
                                        {Opp.irmsStatus == 1 ? "Completed" : Opp.irmsStatus == 2 ? "In Progress" : Opp.irmsStatus == 3 ? "Not Started" : Opp.irmsStatus == 4 ? "Not Required" : Opp.irmsStatus == 5 ? "Rejected" : Opp.irmsStatus == 6 ? "Closed" : "Not Started"}
                                        </p>
                                        <div className='title-with-icon'>
                                            <h3><span className="icon-left"></span>IRMS</h3>
                                            <span className="icon-right">
                                                <a href='#' onClick={IRMSHandler}><img src="./image/icons/info.svg"   alt="IRMS Details"/></a>
                                                {showIRMS && <>
                                                    <div ref={irmsBoxRef} tabIndex="-1" aria-modal="true" className='tooltip-info bottom-tooltip' aria-labelledby="irmsDetails">
                                                        <div className='tooltip-header'>
                                                            <h2 id="irmsDetails">IRMS Details</h2>
                                                            <button aria-label="close" type="button" className="close" onClick={IRMSHandler}>×</button>
                                                        </div>
                                                        <div className='irms-know-more'>
                                                            <ul className='know-more-list'>
                                                                <li className="know-more-item">
                                                                    <div className="label">IRMS ID:</div>
                                                                    <div className="label-value">{Opp.irmsid}</div>
                                                                </li>
                                                                <li className="know-more-item">
                                                                    <div className="label">Status:</div>
                                                                    <div className="label-value">{Opp.status}</div>
                                                                </li>
                                                                <li className="know-more-item">
                                                                    <div className="label">Created On:</div>
                                                                    <div className="label-value">{Opp.createdOn}</div>
                                                                </li>
                                                                <li className="know-more-item">
                                                                    <div className="label">Completed On:</div>
                                                                    <div className="label-value">{Opp.completedOn}</div>
                                                                </li>
                                                                <li className="know-more-item">
                                                                    <div className="label">DCCS Status (Main Case):</div>
                                                                    <div className="label-value">{Opp.dccsStatus}</div>
                                                                </li>
                                                                <li className="know-more-item">
                                                                    <div className="label">FP Status (Main Case):</div>
                                                                    <div className="label-value">{Opp.fpStatus}</div>
                                                                </li>
                                                                <li className="know-more-item">
                                                                    <div className="label">Independence Status:</div>
                                                                    <div className="label-value">{Opp.indpandenceStatus}</div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <p><span>*</span> Some actions are pending with different teams(RBO/ET/RSC/RRO). You can track detailed status in IRMS.</p>

                                                    </div>
                                                    <div className='tooltip-modal'></div></>
                                                }
                                            </span>
                                        </div>
                                        <ul>
                                            <li>
                                            <p style={{position: 'absolute', zIndex: '-1'}}>First Pass status:
                                        {Opp.fpIntStatus == 1 ? "Completed" : Opp.fpIntStatus == 2 ? "In Progress" : Opp.fpIntStatus == 3 ? "Not Started" : Opp.fpIntStatus == 4 ? "Not Required" : Opp.fpIntStatus == 5 ? "Rejected" : Opp.fpIntStatus == 6 ? "Closed" : "Not Started"}
                                        </p>
                                                <span className={Opp.fpIntStatus == 1 ? "icon-left completed-icon-status" : Opp.fpIntStatus == 2 ? "icon-left progress-icon-status" : Opp.fpIntStatus == 3 ? "icon-left not-started-icon-status" : Opp.fpIntStatus == 4 ? "icon-left not-required-icon-status" : Opp.fpIntStatus == 5 ? "icon-left rejected-icon-status" : Opp.fpIntStatus == 6 ? "icon-left closed-icon-status" : "icon-left not-started-icon-status"}   ></span>
                                                First Pass
                                            </li>
                                            <li>
                                            <p style={{position: 'absolute', zIndex: '-1'}}>DCCS status:
                                        {Opp.dccssIntStatus == 1 ? "Completed" : Opp.dccssIntStatus == 2 ? "In Progress" : Opp.dccssIntStatus == 3 ? "Not Started" : Opp.dccssIntStatus == 4 ? "Not Required" : Opp.dccssIntStatus == 5 ? "Rejected" : Opp.dccssIntStatus == 6 ? "Closed" : "Not Started"}
                                        </p>
                                                <span className={Opp.dccssIntStatus == 1 ? "icon-left completed-icon-status" : Opp.dccssIntStatus == 2 ? "icon-left progress-icon-status" : Opp.dccssIntStatus == 3 ? "icon-left not-started-icon-status" : Opp.dccssIntStatus == 4 ? "icon-left not-required-icon-status" : Opp.dccssIntStatus == 5 ? "icon-left rejected-icon-status" : Opp.dccssIntStatus == 6 ? "icon-left closed-lost-icon-status" : "icon-left not-started-icon-status"}  ></span>
                                                DCCS
                                            </li>
                                            <li>
                                            <p style={{position: 'absolute', zIndex: '-1'}}>Independence status:
                                        {Opp.indpandenceIntStatus == 1 ? "Completed" : Opp.indpandenceIntStatus == 2 ? "In Progress" : Opp.indpandenceIntStatus == 3 ? "Not Started" : Opp.indpandenceIntStatus == 4 ? "Not Required" : Opp.indpandenceIntStatus == 5 ? "Rejected" : Opp.indpandenceIntStatus == 6 ? "Closed" : "Not Started"}
                                        </p>
                                                <span className={Opp.indpandenceIntStatus == 1 ? "icon-left completed-icon-status" : Opp.indpandenceIntStatus == 2 ? "icon-left progress-icon-status" : Opp.indpandenceIntStatus == 3 ? "icon-left not-started-icon-status" : Opp.indpandenceIntStatus == 4 ? "icon-left not-required-icon-status" : Opp.indpandenceIntStatus == 5 ? "icon-left rejected-icon-status" : Opp.indpandenceIntStatus == 6 ? "icon-left closed-lost-icon-status" : "icon-left not-started-icon-status"} ></span>
                                                Independence
                                            </li>
                                        </ul>

                                        {MainOppId ? <Link target='_blank' to={`https://inrrohome.deloitte.com/sites/IRMS/SitePages/iRMSSearchResult.aspx?oppid=${MainOppId}&type=IRMS`} >Go To IRMS <span><i aria-hidden='true' className="demo-icon icon-external_link_special">&#x61;</i></span></Link> : ''}
                                        <span className="top-line help-line"></span>

                                        <a href='' className="viewsla" onClick={redirectHandler} style={{
                                            textDecoration: "underline",
                                            cursor: "pointer"
                                        }}>View SLA<span><i aria-hidden='true' className="demo-icon icon-external_link_special">&#x61;</i></span></a>
                                        <span className="top-line help-line"></span>
                                    </div>
                                )) : <p> </p>
                                : <div className="chart-box-info not-started-status" >
                                    <p style={{position: 'absolute', opacity: '0'}}>IRMS Status: Not Started
                                    </p>                                    
                                    <div className='title-with-icon'>
                                        <h3><span className="icon-left"></span> IRMS</h3>
                                        <span className="icon-right">
                                            <a href='#' onClick={IRMSHandler}><img src="./image/icons/info.svg" alt="IRMS Details"  /></a>
                                            {showIRMS && <>
                                                <div ref={irmsBoxRef} tabIndex="-1" aria-modal="true" className='tooltip-info bottom-tooltip' aria-labelledby="irmsDetails">
                                                    <div className='tooltip-header'>
                                                        <h2 id="irmsDetails">IRMS Details</h2>
                                                        <button aria-label="close" type="button" className="close" onClick={IRMSHandler}>×</button>
                                                    </div>
                                                    <div className='irms-know-more'>
                                                        <div className='know-more-list'>
                                                            Data Not Found.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='tooltip-modal'></div></>
                                            }
                                        </span>
                                        
                                    </div>
                                    <ul>
                                        <li>
                                        <p style={{position: 'absolute', zIndex: '-1'}}>First Pass status:Not Started</p>
                                            <span className="icon-left"></span>
                                            First Pass
                                        </li>
                                        <li>
                                        <p style={{position: 'absolute', zIndex: '-1'}}>DCCS status:Not Started</p>
                                            <span className="icon-left"></span>
                                            DCCS
                                        </li>
                                        <li>
                                        <p style={{position: 'absolute', zIndex: '-1'}}>Independence status:Not Started</p>
                                            <span className="icon-left progress-status"></span>
                                            Independence
                                        </li>
                                    </ul>


                                    {MainOppId ? <Link target='_blank' to={`https://inrrohome.deloitte.com/sites/IRMS/SitePages/iRMSSearchResult.aspx?oppid=${MainOppId}&type=IRMS`} >Go To IRMS <span><i aria-hidden='true' className="demo-icon icon-external_link_special">&#x61;</i></span></Link> : ''}



                                    <a href='' className="viewsla" onClick={redirectHandler} style={{
                                        textDecoration: "underline",
                                        cursor: "pointer"
                                    }}>View SLA<span><i aria-hidden='true' className="demo-icon icon-external_link_special">&#x61;</i></span></a>
                                    <span className="top-line help-line"></span>
                                </div>
                            }


                            {drmsOpportunities.length > 0 ?
                                drmsOpportunities ? drmsOpportunities.map((Opp, index) => (
                                    <div key={index} className={Opp.drmsStatus == 1 ? "chart-box-info drms-block completed-status" : Opp.drmsStatus == 2 ? "chart-box-info drms-block progress-status" : Opp.drmsStatus == 3 ? "chart-box-info drms-block not-started-status" : Opp.drmsStatus == 4 ? "chart-box-info drms-block not-required-status" : Opp.drmsStatus == 5 ? "chart-box-info drms-block rejected-status" : Opp.drmsStatus == 6 ? "chart-box-info drms-block closed-status" : "chart-box-info drms-block not-started-status"} >
                                        <p style={{position: 'absolute', opacity: '0'}}>DRMS Status:
                                        {Opp.drmsStatus == 1 ? "Completed" : Opp.drmsStatus == 2 ? "In Progress" : Opp.drmsStatus == 3 ? "Not Started" : Opp.drmsStatus == 4 ? "Not Required" : Opp.drmsStatus == 5 ? "Rejected" : Opp.drmsStatus == 6 ? "Closed" : "Not Started"}
                                        </p>
                                            <div className='title-with-icon'>
                                                <h3><span className="icon-left"></span> DRMS </h3>
                                                <span className="icon-right">
                                                    <a href='#' onClick={DRMSHandler}><img src="./image/icons/info.svg" alt="DRMS details" /></a>
                                                    {showDRMS && <>
                                                        <div ref={drmsBoxRef} tabIndex="-1" aria-modal="true" className='tooltip-info' aria-labelledby="drmsDetails">
                                                            <div className='tooltip-header'>
                                                                <h2 id="drmsDetails">DRMS Details</h2>
                                                                <button aria-label="close" type="button" className="close" onClick={DRMSHandler}>×</button>
                                                            </div>
                                                            <ul className='know-more-list'>
                                                                <li className="know-more-item">
                                                                    <div className="label">CaseID:</div>
                                                                    <div className="label-value">{Opp.drmsCaseID}</div>
                                                                </li>
                                                                <li className="know-more-item">
                                                                    <div className="label">Case Type:</div>
                                                                    <div className="label-value">{Opp.caseType}</div>
                                                                </li>
                                                                <li className="know-more-item">
                                                                    <div className="label">Created By:</div>
                                                                    <div className="label-value">{Opp.createdBy}</div>
                                                                </li>
                                                                <li className="know-more-item">
                                                                    <div className="label">Created On:</div>
                                                                    <div className="label-value">{Opp.createdOn}</div>
                                                                </li>
                                                                <li className="know-more-item">
                                                                    <div className="label">Acceptance Status:</div>
                                                                    <div className="label-value">{Opp.acceptanceStatus}</div>
                                                                </li>
                                                                {/*<div className="know-more-item">*/}
                                                                {/*    <div className="label">Approved By:</div>*/}
                                                                {/*    <div className="label-value">{Opp.approvedBy}</div>*/}
                                                                {/*</div>*/}
                                                                <li className="know-more-item">
                                                                    <div className="label">Approved On:</div>
                                                                    <div className="label-value">{Opp.approvedOn}</div>
                                                                </li>
                                                            </ul>
                                                        </div> <div className='tooltip-modal'></div></>
                                                    }
                                                </span>
                                            </div>
                                        {Opp.drmsCaseID ? <Link target='_blank' to={`https://drms.deloitteresources.com/drms/case/caseview.aspx?CaseId=${Opp.drmsCaseID}`} >Go To DRMS <span><i aria-hidden='true' className="demo-icon icon-external_link_special">&#x61;</i></span></Link> : <Link target='_blank' to="https://drms.deloitteresources.com/drms/home.aspx">Go To DRMS <span><i aria-hidden='true' className="demo-icon icon-external_link_special">&#x61;</i></span></Link>}
                                    </div>
                                )) : <p></p> :
                                <div className="chart-box-info drms-block not-started-status">
                                    <p style={{position: 'absolute', opacity: '0'}}>DRMS Status: Not Started</p>
                                    <div className='title-with-icon'>
                                        <h3><span className="icon-left"></span> DRMS</h3>
                                        <span className="icon-right">
                                            <a href='#' onClick={DRMSHandler}><img src="./image/icons/info.svg" alt="DRMS details" /></a>
                                            {showDRMS && <>
                                                <div ref={drmsBoxRef} tabIndex="-1" aria-modal="true" className='tooltip-info' aria-labelledby="drmsDetails">
                                                    <div className='tooltip-header'>
                                                        <h2 id="drmsDetails">DRMS Details</h2>
                                                        <button aria-label="close" type="button" className="close" onClick={DRMSHandler}>×</button>
                                                    </div>
                                                    <div className='know-more-list'>
                                                        Data Not Found.
                                                    </div>
                                                </div> <div className='tooltip-modal'></div></>
                                            }
                                        </span>
                                    </div>
                                    <Link target='_blank' to="https://drms.deloitteresources.com/drms/home.aspx">Go To DRMS <span><i className="demo-icon icon-external_link_special" aria-hidden='true'>&#x61;</i></span></Link>

                                </div>
                            }

                            {/*{PmsOpportunities.length > 0 ?*/}
                            {/*    PmsOpportunities ? PmsOpportunities.map((Opp, index) => (*/}
                            {/*        <div key={index} className={Opp.pmsStatus == 1 ? "chart-box-info completed-status pms-block" : Opp.pmsStatus == 2 ? "chart-box-info progress-status pms-block" : Opp.pmsStatus == 3 ? "chart-box-info not-started-status pms-block" : Opp.pmsStatus == 4 ? "chart-box-info not-required-status pms-block mb-0" : Opp.pmsStatus == 5 ? "chart-box-info rejected-status pms-block" : Opp.pmsStatus == 6 ? "chart-box-info closed-status pms-block" : "chart-box-info not-started-status pms-block"}>*/}
                            {/*            <h4>*/}
                            {/*                <span className="icon-left"></span>*/}
                            {/*                PMS*/}
                            {/*                <span className="icon-right"><img src="./image/icons/info.svg" alt="" onClick={() => PMSHandler()} /></span>*/}
                            {/*                {showPMS && <>*/}
                            {/*                    <div className='tooltip-info'>*/}
                            {/*                        <div className='tooltip-header'>*/}
                            {/*                            <h4>PMS Details</h4>*/}
                            {/*                            <button type="button" className="close" onClick={() => PMSHandler()}>×</button>*/}
                            {/*                        </div>*/}

                            {/*                        <div key={Opp.opportunityId}>*/}
                            {/*                            <div className='know-more-list'>*/}
                            {/*                                <div className="know-more-item">*/}
                            {/*                                    <div className="label">PMS Status:</div>*/}
                            {/*                                    <div className="label-value">{Opp.pmS_Status}</div>*/}
                            {/*                                </div>*/}
                            {/*                                <div className="know-more-item">*/}
                            {/*                                    <div className="label">Approval Pending With:</div>*/}
                            {/*                                    <div className="label-value">{Opp.approval_PendingWith}</div>*/}
                            {/*                                </div>*/}
                            {/*                                <div className="know-more-item">*/}
                            {/*                                    <div className="label">Approval Submitted On:</div>*/}
                            {/*                                    <div className="label-value">{Opp.approval_SubmittedOn}</div>*/}
                            {/*                                </div>*/}
                            {/*                                <div className="know-more-item">*/}
                            {/*                                    <div className="label">Approval Ageing (In Days):</div>*/}
                            {/*                                    <div className="label-value">{Opp.approvalAgeIn}</div>*/}
                            {/*                                </div>*/}
                            {/*                            </div>*/}
                            {/*                        </div>*/}



                            {/*                    </div> <div className='tooltip-modal'></div></>*/}
                            {/*                }*/}
                            {/*            </h4>*/}
                            {/*            <Link target='_blank' to="https://indta.deloitte.com/PMS">Go To PMS <span><i className="demo-icon icon-external_link_special">&#x61;</i></span></Link>*/}
                            {/*            <span className="bottom-line"></span>*/}
                            {/*        </div>*/}
                            {/*    )) : <p> </p>*/}
                            {/*    :*/}
                            {/*    <div className="chart-box-info not-started-status pms-block" >*/}
                            {/*        <h4>*/}
                            {/*            <span className="icon-left"></span>*/}
                            {/*            PMS*/}
                            {/*            <span className="icon-right"><img src="./image/icons/info.svg" alt="" onClick={() => PMSHandler()} /></span>*/}
                            {/*            {showPMS && <>*/}
                            {/*                <div className='tooltip-info'>*/}
                            {/*                    <div className='tooltip-header'>*/}
                            {/*                        <h4>PMS Details</h4>*/}
                            {/*                        <button type="button" className="close" onClick={() => PMSHandler()}>×</button>*/}
                            {/*                    </div>*/}
                            {/*                    <div className='know-more-list'>*/}
                            {/*                        Data Not Found.*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*                <div className='tooltip-modal'></div></>*/}
                            {/*            }*/}
                            {/*        </h4>*/}
                            {/*        <Link target='_blank' to="https://indta.deloitte.com/PMS">Go To PMS <span><i className="demo-icon icon-external_link_special">&#x61;</i></span></Link>*/}
                            {/*        */}{/* <span className="bottom-line"></span> */}
                            {/*    </div>*/}
                            {/*}*/}

                            {PmsOpportunities.length > 0 ?
                                PmsOpportunities ? PmsOpportunities.map((Opp, index) => (
                                    <div key={index} className={Opp.pmsStatus == 1 ? "chart-box-info completed-status pms-block" : Opp.pmsStatus == 2 ? "chart-box-info progress-status pms-block" : Opp.pmsStatus == 3 ? "chart-box-info not-started-status pms-block" : Opp.pmsStatus == 4 ? "chart-box-info not-required-status pms-block mb-0" : Opp.pmsStatus == 5 ? "chart-box-info rejected-status pms-block" : Opp.pmsStatus == 6 ? "chart-box-info closed-status pms-block" : "chart-box-info not-started-status pms-block"}>
                                        <p style={{position: 'absolute', opacity: '0'}}>P2W Status:
                                        {Opp.pmsStatus == 1 ? "Completed" : Opp.pmsStatus == 2 ? "In Progress" : Opp.pmsStatus == 3 ? "Not Started" : Opp.pmsStatus == 4 ? "Not Required" : Opp.pmsStatus == 5 ? "Rejected" : Opp.pmsStatus == 6 ? "Closed" : "Not Started"}
                                        </p>
                                        <div className='title-with-icon'>
                                            <h3><span className="icon-left"></span> P2W</h3>
                                            <span className="icon-right">
                                                <a href='#' onClick={PMSHandler}><img src="./image/icons/info.svg" alt="P2W Details"  /></a>
                                            </span>
                                            {showPMS && <>
                                                <div ref={p2wBoxRef} tabIndex="-1" aria-modal="true" className='tooltip-info' aria-labelledby="p2wDetails">
                                                    <div className='tooltip-header'>
                                                        <h2 id="p2wDetails">P2W Details</h2>
                                                        <button aria-label="close" type="button" className="close" onClick={PMSHandler}>×</button>
                                                    </div>

                                                    <div key={Opp.opportunityId}>
                                                        <ul className='know-more-list'>
                                                            <li className="know-more-item">
                                                                <div className="label">P2W Status:</div>
                                                                <div className="label-value">{Opp.pmS_Status}</div>
                                                            </li>
                                                            <li className="know-more-item">
                                                                <div className="label">Approval Pending With:</div>
                                                                <div className="label-value">{Opp.approval_PendingWith}</div>
                                                            </li>
                                                            <li className="know-more-item">
                                                                <div className="label">Approval Submitted On:</div>
                                                                <div className="label-value">{Opp.approval_SubmittedOn}</div>
                                                            </li>
                                                            <li className="know-more-item">
                                                                <div className="label">Approval Ageing (In Days):</div>
                                                                <div className="label-value">{Opp.approvalAgeIn}</div>
                                                            </li>
                                                        </ul>
                                                    </div>



                                                </div> <div className='tooltip-modal'></div></>
                                            }
                                        </div>
                                        <Link target='_blank' to={`https://price2win.lightning.force.com/lightning/r/Opportunity/${Opp.p2WId}/view`}>Go To P2W <span><i aria-hidden='true' className="demo-icon icon-external_link_special">&#x61;</i></span></Link>
                                        {/*<span className="bottom-line"></span>*/}
                                    </div>
                                )) : <p> </p>
                                :
                                <div className="chart-box-info not-started-status pms-block" >
                                    <p style={{position: 'absolute', opacity: '0'}}>P2W Status: Not Strated</p>
                                    <div className='title-with-icon'>
                                        <h3><span className="icon-left"></span> P2W</h3>
                                        <span className="icon-right">
                                            <a href='#' onClick={PMSHandler}><img src="./image/icons/info.svg" alt="P2W Details"  /></a>
                                        </span>
                                        {showPMS && <>
                                            <div ref={p2wBoxRef} tabIndex="-1" aria-modal="true" className='tooltip-info' aria-labelledby="p2wDetails">
                                                <div className='tooltip-header'>
                                                    <h2 id="p2wDetails">P2W Details</h2>
                                                    <button aria-label="close" type="button" className="close" onClick={PMSHandler}>×</button>
                                                </div>
                                                <div className='know-more-list'>
                                                    Data Not Found.
                                                </div>
                                            </div>
                                            <div className='tooltip-modal'></div></>
                                        }
                                    </div>                                    
                                    <Link target='_blank' to="https://price2win.lightning.force.com/lightning/page/home">Go To P2W <span><i aria-hidden='true' className="demo-icon icon-external_link_special">&#x61;</i></span></Link>
                                    {/* <span className="bottom-line"></span> */}
                                </div>
                            }


                            {drbOpportunities.length > 0 ?
                                drbOpportunities ? drbOpportunities.map((Opp, index) => (
                                    <div key={index} className={Opp.drbStatus == 1 ? "chart-box-info completed-status drb-block" : Opp.drbStatus == 2 ? "chart-box-info progress-status drb-block" : Opp.drbStatus == 3 ? "chart-box-info not-started-status drb-block" : Opp.drbStatus == 4 ? "chart-box-info not-required-status drb-block" : Opp.drbStatus == 5 ? "chart-box-info rejected-status drb-block mb-0" : Opp.drbStatus == 6 ? "chart-box-info closed-status drb-block" : "chart-box-info not-started-status drb-block"}>
                                        <p style={{position: 'absolute', opacity: '0'}}>DRB Status:
                                        {Opp.drbStatus == 1 ? "Completed" : Opp.drbStatus == 2 ? "In Progress" : Opp.drbStatus == 3 ? "Not Started" : Opp.drbStatus == 4 ? "Not Required" : Opp.drbStatus == 5 ? "Rejected" : Opp.drbStatus == 6 ? "Closed" : "Not Started"}
                                        </p>
                                        <div className='title-with-icon'>
                                            <h3><span className="icon-left"></span> DRB</h3>
                                            <span className="icon-right">
                                                <a href='#' onClick={DRBHandler}>
                                                <img src="./image/icons/info.svg" alt="DRB details"  />
                                                </a>
                                            </span>
                                            {showDRB && <>
                                                <div ref={drbBoxRef} tabIndex="-1" aria-modal="true" className='tooltip-info' aria-labelledby="drbDetails">
                                                    <div className='tooltip-header'>
                                                        <h2 id="drbDetails">DRB Details</h2>
                                                        <button aria-label="close" type="button" className="close" onClick={DRBHandler}>×</button>
                                                    </div>

                                                    <div key={Opp.opportunityId}>
                                                        <ul className='know-more-list'>
                                                            <li className="know-more-item">
                                                                <div className="label">Name:</div>
                                                                <div className="label-value">{Opp.name}</div>
                                                            </li>
                                                            <li className="know-more-item">
                                                                <div className="label">Lead Business:</div>
                                                                <div className="label-value">{Opp.leadBusiness}</div>
                                                            </li>
                                                            <li className="know-more-item">
                                                                <div className="label">Pursuit Partner:</div>
                                                                <div className="label-value">{Opp.pursuitPartner}</div>
                                                            </li>
                                                            <li className="know-more-item">
                                                                <div className="label">Function DRB Status:</div>
                                                                <div className="label-value">{Opp.functionalDRB_Status}</div>
                                                            </li>
                                                            <li className="know-more-item">
                                                                <div className="label">Firm DRB Status:</div>
                                                                <div className="label-value">{Opp.firmDRB_Status}</div>
                                                            </li>
                                                        </ul>
                                                    </div>



                                                </div> <div className='tooltip-modal'></div></>
                                            }
                                        </div>
                                        <Link target='_blank' to={`https://indigital.lightning.force.com/${Opp.drbid}`}>Go To DRB <span><i aria-hidden='true' className="demo-icon  icon-external_link_special">&#x61;</i></span></Link>
                                        <span className="bottom-line"></span>
                                    </div>
                                )) : <p> </p>
                                :
                                <div className="chart-box-info not-started-status drb-block mb-0" >
                                    <p style={{position: 'absolute', opacity: '0'}}>DRB Status:Not Started</p>
                                        <div className='title-with-icon'>
                                            <h3><span className="icon-left"></span> DRB</h3>
                                            <span className="icon-right">
                                                    <a href='#' onClick={DRBHandler}>
                                                        <img src="./image/icons/info.svg" alt="DRB details"  />
                                                    </a>
                                            </span>
                                            {showDRB && <>
                                                <div ref={drbBoxRef} tabIndex="-1" aria-modal="true" className='tooltip-info' aria-labelledby="drbDetails">
                                                    <div className='tooltip-header'>
                                                        <h2 id="drbDetails">DRB Details</h2>
                                                        <button aria-label="close" type="button" className="close" onClick={DRBHandler}>×</button>
                                                    </div>
                                                    <div className='know-more-list'>
                                                        Data Not Found.
                                                    </div>
                                                </div>
                                                <div className='tooltip-modal'></div></>
                                            }
                                        </div>
                                    
                                    <Link target='_blank' to="https://indigital.lightning.force.com/lightning/n/DRB">Go To DRB <span><i className="demo-icon icon-external_link_special" aria-hidden='true'>&#x61;</i></span></Link>
                                    <span className="bottom-line"></span>
                                </div>
                            }
                            {/*{JupiterCountry === 'IN' ?*/}
                            {/*  <>*/}
                            {/*   </>*/}
                            {/*    :*/}

                            {/*    <div className="chart-box-info not-required-status pms-block mb-0" >*/}
                            {/*        <h4>*/}
                            {/*            <span className="icon-left"></span>*/}
                            {/*            PMS*/}
                            {/*            <span className="icon-right"><img src="./image/icons/info.svg" alt="" onClick={() => PMSHandler()} /></span>*/}
                            {/*            {showPMS && <>*/}
                            {/*                <div className='tooltip-info'>*/}
                            {/*                    <div className='tooltip-header'>*/}
                            {/*                        <h4>PMS Details</h4>*/}
                            {/*                        <button type="button" className="close" onClick={() => PMSHandler()}>×</button>*/}
                            {/*                    </div>*/}
                            {/*                    <div className='know-more-list'>*/}
                            {/*                        Data Not Found.*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*                <div className='tooltip-modal'></div></>*/}
                            {/*            }*/}
                            {/*        </h4>*/}
                            {/*        <Link target='_blank' to="https://indta.deloitte.com/PMS">Go To PMS <span><i className="demo-icon icon-external_link_special">&#x61;</i></span></Link>*/}
                            {/*        <span className="bottom-line"></span>*/}
                            {/*        </div>*/}
                            {/*    }*/}






                            <span className="level-3-line"></span>
                        </div>
                    </div>
                    {ECM2Records.length > 0 ?
                        <div className={ECM2Records[0].ecmStatus == 1 ? "chart-box completed-status ecm-block" : ECM2Records[0].ecmStatus == 2 ? "chart-box progress-status ecm-block" : ECM2Records[0].ecmStatus == 3 ? "chart-box not-started-status ecm-block" : ECM2Records[0].ecmStatus == 4 ? "chart-box not-required-status ecm-block" : ECM2Records[0].ecmStatus == 5 ? "chart-box rejected-status ecm-block" : ECM2Records[0].ecmStatus == 6 ? "chart-box closed-status ecm-block" : "chart-box not-started-status ecm-block"} >
                            <p style={{position: 'absolute', opacity: '0'}}>ECM Status:
                                        {ECM2Records[0].ecmStatus == 1 ? "Completed" : ECM2Records[0].ecmStatus == 2 ? "In Progress" : ECM2Records[0].ecmStatus == 3 ? "Not Started" : ECM2Records[0].ecmStatus == 4 ? "Not Required" : ECM2Records[0].ecmStatus == 5 ? "Rejected" : ECM2Records[0].ecmStatus == 6 ? "Closed" : "Not Started"}
                                        </p>
                            <div className="chart-box-info">
                                <div className='title-with-icon'>
                                    <h3><span className="icon-left"></span> ECM</h3>
                                    <div className='tooltip-block'>
                                        <span className="icon-right">
                                            <a href='#' ref={ecmMoreRef} onClick={ECMHandler}>
                                            <img src="./image/icons/info.svg" alt="ECM details" />
                                            </a>
                                        </span>
                                        {showECM && <>
                                            <div ref={ecmBoxRef} tabIndex="-1" aria-modal="true" className='tooltip-info ecm-tooltip-info' aria-labelledby="ecmDetails">
                                                <div className='tooltip-header' id="ecmDetails">
                                                    <h2>ECM Details</h2>
                                                    <button aria-label="close"  type="button" className="close" onClick={ECMHandler}>×</button>
                                                </div>
                                                {ECM2Records.map((Opp, i) => (
                                                    <ul key={i} className='know-more-list'>
                                                        <li className="know-more-item-header">
                                                            Document Type :  {Opp.documentType}
                                                        </li>
                                                        <li className="know-more-item">
                                                            <div className="label">Status:</div>
                                                            <div className="label-value">{Opp.status}</div>
                                                        </li>
                                                        <li className="know-more-item">
                                                            <div className="label">To be actioned by:</div>
                                                            <div className="label-value">{Opp.toBeActionBy}</div>
                                                        </li>
                                                        <li className="know-more-item">
                                                            <div className="label">Last Actioned By:</div>
                                                            <div className="label-value">{Opp.lastActionedBy}</div>
                                                        </li>
                                                        <li className="know-more-item">
                                                            <div className="label">Last Action Date:</div>
                                                            <div className="label-value">{Opp.lastActionDate}</div>
                                                        </li>
                                                        <li className="know-more-item">
                                                            <div className="label">Days since Last Action:</div>
                                                            <div className="label-value">{Opp.daysSinceLastAction}</div>
                                                        </li>

                                                    </ul>
                                                ))}
                                                {ecmOpportunities.length > 2 ?
                                                    <div className='know-more-list_popup'>
                                                        <a href='#' className='more-info-popup' data-toggle="modal" data-target="#moreECMInfoModal" onClick={handleMoreInfoECMClick} aria-label='More info Tap to expand ECM details'>More Info</a>
                                                    </div> : <div></div>
                                                }
                                            </div>
                                            <div className='tooltip-modal'></div></>
                                        }
                                    </div>
                                </div> 
                                <Link target='_blank' to="https://indhome.deloitte.com/sites/DigitalEL/SitePages/RequestorWorkspace.aspx">Go To ECM<span><i aria-hidden='true' className="demo-icon icon-external_link_special">&#x61;</i></span></Link>
                            </div>
                        </div>
                        :
                        <div className="chart-box not-started-status ecm-block">
                            <p style={{position: 'absolute', opacity: '0'}}>ECM Status: Not Started</p>
                            <div className="chart-box-info">
                            
                                <div className='title-with-icon'>
                                    <h3><span className="icon-left"></span> ECM </h3>
                                    <div className='tooltip-block'>
                                        <span className="icon-right">
                                            <a href='#' onClick={ECMHandler}>
                                                <img src="./image/icons/info.svg" alt="ECM details" />
                                            </a>
                                        </span>
                                        {showECM && <>
                                            <div ref={ecmBoxRef} tabIndex="-1" aria-modal="true" className='tooltip-info empty-tooltip ecm-tooltip-info' aria-labelledby="ecmDetails">
                                                <div className='tooltip-header' id="ecmDetails">
                                                    <h2>ECM Details</h2>
                                                    <button aria-label="close" type="button" className="close" onClick={ECMHandler}>×</button>
                                                </div>
                                                <div className='know-more-list'>
                                                    Data Not Found.
                                                </div>
                                            </div>
                                            <div className='tooltip-modal'></div></>
                                        }
                                    </div>
                                </div>
                                
                                <Link target='_blank' to="https://indhome.deloitte.com/sites/DigitalEL/SitePages/RequestorWorkspace.aspx">Go To ECM<span><i aria-hidden='true' className="demo-icon icon-external_link_special">&#x61;</i></span></Link>
                            </div>
                        </div>
                    }
                    {/*{JupiterCountry === 'IN' ?*/}
                    {/*    <>*/}

                    {/*    </>*/}
                    {/*    :*/}
                    {/*    <div className="chart-box not-required-status">*/}
                    {/*        <div className="chart-box-info">*/}
                    {/*            <h4>*/}
                    {/*                <span className="icon-left"></span>*/}
                    {/*                ECM*/}
                    {/*                <div className='tooltip-block'>*/}
                    {/*                    <span className="icon-right"><img src="./image/icons/info.svg" alt="" onClick={ECMHandler} /></span>*/}
                    {/*                    {showECM && <>*/}
                    {/*                        <div className='tooltip-info empty-tooltip ecm-tooltip-info'>*/}
                    {/*                            <div className='tooltip-header'>*/}
                    {/*                                <h4>ECM Details</h4>*/}
                    {/*                                <button type="button" className="close" onClick={() => ECMHandler()}>×</button>*/}
                    {/*                            </div>*/}
                    {/*                            <div className='know-more-list'>*/}
                    {/*                                Data Not Found.*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                        <div className='tooltip-modal'></div></>*/}
                    {/*                    }*/}
                    {/*                </div>*/}
                    {/*            </h4>*/}
                    {/*            <Link target='_blank' to="https://indhome.deloitte.com/sites/DigitalEL/SitePages/RequestorWorkspace.aspx">Go To ECM <span><i className="demo-icon icon-external_link_special">&#x61;</i></span></Link>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*}*/}

                    <div role="group" className='sap-eds-block' >
                        <p style={{position: 'absolute', opacity: '0', zIndex: '-1'}}>Group of CRP, SAP & EDS Block</p>
                        <span class="level-3-line"></span>
                        {CRP5Records.length > 0 ?
                            <div className={CRP5Records[0].crpStatus == 1 ? "chart-box completed-status crp-block" : CRP5Records[0].crpStatus == 2 ? "chart-box progress-status crp-block" : CRP5Records[0].crpStatus == 3 ? "chart-box not-started-status crp-block" : CRP5Records[0].crpStatus == 4 ? "chart-box not-required-status crp-block" : CRP5Records[0].crpStatus == 5 ? "chart-box rejected-status crp-block" : CRP5Records[0].crpStatus == 6 ? "chart-box closed-status crp-block" : "chart-box not-started-status crp-block"} >
                                <p style={{position: 'absolute', opacity: '0'}}>CRP Status:
                                        {CRP5Records[0].crpStatus == 1 ? "Completed" : CRP5Records[0].crpStatus == 2 ? "In Progress" : CRP5Records[0].crpStatus == 3 ? "Not Started" : CRP5Records[0].crpStatus == 4 ? "Not Required" : CRP5Records[0].crpStatus == 5 ? "Rejected" : CRP5Records[0].crpStatus == 6 ? "Closed" : "Not Started"}
                                        </p>
                                <div className="chart-box-info">
                                    <div className='title-with-icon'>
                                        <h3><span className="icon-left"></span> CRP</h3>
                                        <span className="icon-right">
                                            <a href='#' ref={crpMoreRef} onClick={CRPHandler}><img src="./image/icons/info.svg" alt="CRP details"  /></a>
                                        </span>
                                        {showCRP && <>
                                            <div ref={crpBoxRef} tabIndex="-1" aria-modal="true" className='tooltip-info ecm-tooltip-info' aria-labelledby="crpDetails">
                                                <div className='tooltip-header' id="crpDetails">
                                                    <h2>CRP Details</h2>
                                                    <button aria-label="close" type="button" className="close" onClick={CRPHandler}>×</button>
                                                </div>
                                                {CRP5Records.map((Opp, i) => (

                                                    <div key={i} className='know-more-list'>
                                                        <ul className='sap-know-more-list'>
                                                            <li className="know-more-item">
                                                                <div className="label">Request Id:</div>
                                                                <div className="label-value">{Opp.requestId}</div>
                                                            </li>
                                                            {/*<div className="know-more-item">*/}
                                                            {/*    <div className="label">Engagement Code:</div>*/}
                                                            {/*    <div className="label-value">{Opp.engagementNumber}</div>*/}
                                                            {/*</div>*/}
                                                            <li className="know-more-item">
                                                                <div className="label">Status:</div>
                                                                <div className="label-value">{Opp.engStatusText}</div>
                                                            </li>
                                                            <li className="know-more-item">
                                                                <div className="label">Pending With:</div>
                                                                <div className="label-value">{Opp.pendingWith}</div>
                                                            </li>
                                                            <li className="know-more-item">
                                                                <div className="label">Created On:</div>
                                                                <div className="label-value">{Opp.createdOn}</div>
                                                            </li>
                                                            {/*<div className="know-more-item">*/}
                                                            {/*    <div className="label">Engagement End Date:</div>*/}
                                                            {/*    <div className="label-value">{Opp.eng_Enddate}</div>*/}
                                                            {/*</div>*/}
                                                        </ul>
                                                    </div>

                                                ))}
                                                {crpOpportunities.length > 3 ?
                                                    <div className='know-more-list_popup'>
                                                        <a href='' aria-label="More info Tap to expand CRP details" className='more-info-popup' data-toggle="modal" data-target="#moreCRPInfoModal" onClick={handleMoreInfoCRPClick}>More Info</a>
                                                    </div> : <div></div>
                                                }
                                            </div> <div className='tooltip-modal'></div></>
                                        }
                                    </div>
                                    
                                    <Link target='_blank' to="https://inmyboard.deloitte.com/opportunity">Go To CRP <span><i className="demo-icon icon-external_link_special" aria-hidden='true'>&#x61;</i></span></Link>

                                </div>
                            </div>

                            :
                            <div className="chart-box not-started-status crp-block">
                                <p style={{position: 'absolute', opacity: '0'}}>CRP Status:Not started</p>
                                <div className="chart-box-info">
                                    <div className='title-with-icon'>
                                        <h3><span className="icon-left"></span> CRP</h3>
                                        <span className="icon-right">
                                            <a href='#' onClick={CRPHandler}><img src="./image/icons/info.svg" alt="CRP details"  /></a>
                                        </span>
                                        {showCRP && <>
                                            <div ref={crpBoxRef} tabIndex="-1" aria-modal="true" className='tooltip-info empty-tooltip ecm-tooltip-info' aria-labelledby="crpDetails">
                                                <div className='tooltip-header' id="crpDetails">
                                                    <h2>CRP Details</h2>
                                                    <button  aria-label="close" type="button" className="close" onClick={CRPHandler}>×</button>
                                                </div>
                                                <div className='know-more-list'>
                                                    Data Not Found.
                                                </div>

                                            </div>
                                            <div className='tooltip-modal'></div></>
                                        }                                        
                                    </div>
                                    
                                    <Link target='_blank' to="https://inmyboard.deloitte.com/opportunity">Go To CRP <span><i className="demo-icon icon-external_link_special" aria-hidden='true'>&#x61;</i></span></Link>


                                </div>
                            </div>
                        }


                        {SAP2Records.length > 0 ?
                            <div className={SAP2Records[0].sapStatus == 1 ? "chart-box completed-status sap-block" : SAP2Records[0].sapStatus == 2 ? "chart-box progress-status sap-block" : SAP2Records[0].sapStatus == 3 ? "chart-box not-started-status sap-block" : SAP2Records[0].sapStatus == 4 ? "chart-box not-required-status sap-block" : SAP2Records[0].sapStatus == 5 ? "chart-box rejected-status sap-block" : SAP2Records[0].sapStatus == 6 ? "chart-box closed-status sap-block" : "chart-box not-started-status sap-block"} >
                                <p style={{position: 'absolute', opacity: '0'}}>SAP Status:
                                        {SAP2Records[0].sapStatus == 1 ? "Completed" : SAP2Records[0].sapStatus == 2 ? "In Progress" : SAP2Records[0].sapStatus == 3 ? "Not Started" : SAP2Records[0].sapStatus == 4 ? "Not Required" : SAP2Records[0].sapStatus == 5 ? "Rejected" : SAP2Records[0].sapStatus == 6 ? "Closed" : "Not Started"}
                                        </p>
                                <div className="chart-box-info">
                                    <div className='title-with-icon'>
                                        <h3><span className="icon-left"></span> SAP</h3>
                                        <span className="icon-right">
                                            <a href='#' ref={sapMoreRef} onClick={SAPHandler}>
                                                <img src="./image/icons/info.svg" alt="SAP details"  />
                                            </a>
                                        </span>
                                        {showSAP && <>
                                            <div ref={sapBoxRef} tabIndex="-1" aria-modal="true" className='tooltip-info ecm-tooltip-info' aria-labelledby="sapDetails">
                                                <div className='tooltip-header' id="sapDetails">
                                                    <h2>SAP Details</h2>
                                                    <button aria-label="close" type="button" className="close" onClick={SAPHandler}>×</button>
                                                </div>
                                                {SAP2Records.map((Opp, i) => (

                                                    <div key={i} className='know-more-list'>
                                                        <ul className='sap-know-more-list'>
                                                            <li className="know-more-item">
                                                                <div className="label">Engagement Code:</div>
                                                                <div className="label-value">{Opp.eng_Code}</div>
                                                            </li>
                                                            <li className="know-more-item">
                                                                <div className="label">Engagement Partner:</div>
                                                                <div className="label-value">{Opp.eng_Partner}</div>
                                                            </li>
                                                            <li className="know-more-item">
                                                                <div className="label">Created On:</div>
                                                                <div className="label-value">{Opp.createdOn}</div>
                                                            </li>
                                                            <li className="know-more-item">
                                                                <div className="label">Engagement Start Date:</div>
                                                                <div className="label-value">{Opp.eng_StartDate}</div>
                                                            </li>
                                                            <li className="know-more-item">
                                                                <div className="label">Engagement End Date:</div>
                                                                <div className="label-value">{Opp.eng_Enddate}</div>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                ))}
                                                {SapOpportunities.length > 2 ?
                                                    <div className='know-more-list_popup'>
                                                        <a href='' className='more-info-popup' data-toggle="modal" data-target="#moreSAPInfoModal"
                                                            onClick={handleMoreInfoSAPClick} aria-label="More info Tap to expand SAP details"
                                                        >More Info</a>
                                                    </div> : <div></div>
                                                }
                                            </div> <div className='tooltip-modal'></div></>
                                        }
                                    </div>
                                    
                                    <Link target='_blank' to="https://fsp.deloitteresources.com/irj/portal">Go To SAP <span><i className="demo-icon icon-external_link_special" aria-hidden='true'>&#x61;</i></span></Link>

                                </div>
                            </div>

                            :
                            <div className="chart-box not-started-status sap-block">
                                <p style={{position: 'absolute', opacity: '0'}}>SAP Status:Not Started</p>
                                <div className="chart-box-info">
                                    <div className='title-with-icon'>
                                            <h3><span className="icon-left"></span>SAP</h3>
                                            <span className="icon-right">
                                                <a href='#' onClick={SAPHandler}>
                                                    <img src="./image/icons/info.svg" alt="SAP details"  />
                                                </a>
                                            </span>
                                            {showSAP && <>
                                                <div ref={sapBoxRef} tabIndex="-1" aria-modal="true" className='tooltip-info empty-tooltip ecm-tooltip-info' aria-labelledby="sapDetails">
                                                    <div className='tooltip-header' id="sapDetails">
                                                        <h2>SAP Details</h2>
                                                        <button aria-label="close" type="button" className="close" onClick={SAPHandler}>×</button>
                                                    </div>
                                                    <div className='know-more-list'>
                                                        Data Not Found.
                                                    </div>
                                                
                                                </div>
                                                <div className='tooltip-modal'></div></>
                                            }
                                        
                                    </div>                                
                                    
                                    <Link target='_blank' to="https://fsp.deloitteresources.com/irj/portal">Go To SAP <span><i className="demo-icon icon-external_link_special" aria-hidden='true'>&#x61;</i></span></Link>


                                </div>
                            </div>
                        }

                        {EDS3Records.length > 0 ?
                            <div className={EDS3Records[0].edsStatus == 1 ? "chart-box completed-status eds-block" : EDS3Records[0].edsStatus == 2 ? "chart-box progress-status eds-block" : EDS3Records[0].edsStatus == 3 ? "chart-box not-started-status eds-block" : EDS3Records[0].edsStatus == 4 ? "chart-box not-required-status eds-block" : EDS3Records[0].edsStatus == 5 ? "chart-box rejected-status eds-block" : EDS3Records[0].edsStatus == 6 ? "chart-box closed-status eds-block" : "chart-box not-started-status eds-block"} >
                                <p style={{position: 'absolute', opacity: '0'}}>EDS Status:
                                        {EDS3Records[0].edsStatus == 1 ? "Completed" : EDS3Records[0].edsStatus == 2 ? "In Progress" : EDS3Records[0].edsStatus == 3 ? "Not Started" : EDS3Records[0].edsStatus == 4 ? "Not Required" : EDS3Records[0].edsStatus == 5 ? "Rejected" : EDS3Records[0].edsStatus == 6 ? "Closed" : "Not Started"}
                                        </p>
                                <div className="chart-box-info">
                                    <div className='title-with-icon'>
                                        <h3><span className="icon-left"></span> EDS</h3>
                                        <span className="icon-right">
                                            <a href='#' onClick={EDSHandler}>
                                                <img src="./image/icons/info.svg" alt="EDS details"  />
                                            </a>
                                            </span>
                                        {showEDS && <>
                                            <div ref={edsBoxRef} tabIndex="-1" aria-modal="true" className='tooltip-info' aria-labelledby="edsDetails">
                                                <div className='tooltip-header' id="edsDetails">
                                                    <h2>EDS Details</h2>
                                                    <button aria-label="close" type="button" className="close" onClick={EDSHandler}>×</button>
                                                </div>


                                                {


                                                    EDS3Records.map((Opp, i) => (

                                                        <div key={i} className='know-more-list'>
                                                            <ul className='sap-know-more-list'>
                                                                <li className="know-more-item">
                                                                    <div className="label">Engagement Code:</div>
                                                                    <div className="label-value">{Opp.engagementNumber}</div>
                                                                </li>
                                                                <li className="know-more-item">
                                                                    <div className="label">Status:</div>
                                                                    <div className="label-value">{Opp.status}</div>
                                                                </li>
                                                                <li className="know-more-item">
                                                                    <div className="label">Engagement Created Date:</div>
                                                                    <div className="label-value">{Opp.createdOn}</div>
                                                                </li>
                                                            </ul>
                                                        </div>

                                                    ))}
                                                {edsOpportunities.length > 3 ?
                                                    <div className='know-more-list_popup'>
                                                        <a href='' aria-label="More info Tap to expand EDS details" className='more-info-popup' data-toggle="modal" data-target="#moreEDSInfoModal"
                                                            onClick={handleMoreInfoEDSClick}
                                                        >More Info</a>
                                                    </div> : <div></div>
                                                }
                                            </div>
                                            <div className='tooltip-modal'></div></>
                                        }
                                    </div>
                                    <Link target='_blank' to="https://indpoints.deloitte.com/">Go To EDS <span><i className="demo-icon icon-external_link_special" aria-hidden='true'>&#x61;</i></span></Link>

                                </div>
                            </div>

                            :
                            <div className="chart-box not-started-status eds-block">
                                <p style={{position: 'absolute', opacity: '0'}}>EDS Status:Not started</p>
                                <div className="chart-box-info">
                                    <div className='title-with-icon'>
                                            <h3><span className="icon-left"></span> EDS</h3>
                                            <span className="icon-right">
                                                <a href='#' onClick={EDSHandler}>
                                                    <img src="./image/icons/info.svg" alt="EDS details"  />
                                                </a>
                                            </span>
                                            {showEDS && <>
                                                <div ref={edsBoxRef} tabIndex="-1" aria-modal="true" className='tooltip-info empty-tooltip' aria-labelledby="edsDetails">
                                                    <div className='tooltip-header' id="edsDetails">
                                                        <h2>EDS Details</h2>
                                                        <button aria-label="close" type="button" className="close" onClick={EDSHandler}>×</button>
                                                    </div>
                                                    <div className='know-more-list'>
                                                        Data Not Found.
                                                    </div>
                                                </div>
                                                <div className='tooltip-modal'></div></>
                                            }
                                    </div>
                                    <Link target='_blank' to="https://indpoints.deloitte.com">Go To EDS <span><i className="demo-icon icon-external_link_special" aria-hidden='true'>&#x61;</i></span></Link>

                                </div>
                            </div>
                        }

                    </div>





                    {pmsBusiness == 'Consulting' ?
                        <div className='offline-process mob-view'>
                            <div href="#" className="hexagon-btn ribbon-outset border">
                                PCOE
                                <div className='offline-process-dot'></div>
                            </div>
                            <div href="#" className="hexagon-btn ribbon-outset border">SRB<div className='offline-process-dot'></div></div>
                            <div href="#" className="hexagon-btn ribbon-outset border">DRB</div>
                            <div className='offline-process-heading'>
                                Offline <br /> Proccess
                            </div>
                        </div>
                        : <p></p>}

                    <div className='timeline-block'>
                        <h2 className='timeline-header'>
                            Timeline Overview <img aria-hidden='true' src="./image/timeline-white.svg" alt=''/>
                        </h2>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>O2E System</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Jupiter</td>
                                    <td>{Opportunities.length > 0 ? Opportunities[0].createdOn : <p>---</p>}</td>
                                    <td>{Opportunities.length > 0 ? Opportunities[0].jupiterStatus == 1 ? Opportunities[0].updatedOn : <p>---</p> : <p>---</p>}</td>
                                </tr>
                                <tr>
                                    <td>IRMS</td>
                                    <td>{irmsOpportunities.length > 0 ? irmsOpportunities[0].createdOn : <p>---</p>}</td>
                                    <td>{irmsOpportunities.length > 0 ? irmsOpportunities[0].irmsStatus == 1 ? irmsOpportunities[0].completedOn : <p>---</p> : <p>---</p>}</td>
                                </tr>
                                <tr>
                                    <td>DRMS</td>
                                    <td>{drmsOpportunities.length > 0 ? drmsOpportunities[0].createdOn : <p>---</p>}</td>
                                    <td>{drmsOpportunities.length > 0 ? drmsOpportunities[0].drmsStatus == 1 ? drmsOpportunities[0].approvedOn : <p>---</p> : <p>---</p>}</td>
                                </tr>
                                <tr>
                                    <td>P2W</td>
                                    <td>{PmsOpportunities.length > 0 ? PmsOpportunities[0].createdOn : <p>---</p>}</td>
                                    <td>{PmsOpportunities.length > 0 ? PmsOpportunities[0].pmsStatus == 1 ? PmsOpportunities[0].updatedOn : <p>---</p> : <p>---</p>}</td>
                                </tr>
                                {ecmOpportunities.length > 0 ?
                                    //ecmOpportunities.map((Opp, i) => (
                                    //    <tr key={i}>
                                    //        <td>ECM- {Opp.documentType}</td>
                                    //        <td>{Opp.createdOn}</td>
                                    //        <td>{Opp.ecmStatus == 1 ? Opp.lastActionDate : <p>---</p>}</td>
                                    //    </tr>


                                    //)) : ""}
                                    <>
                                        <tr>
                                            <td>ECM</td>
                                            <td>{ecmOpportunities.length > 0 ? ecmOpportunities[0].createdOn : <p>---</p>}</td>
                                            <td>{ecmOpportunities.length > 0 ? ecmOpportunities[0].ecmStatus == 1 ? ecmOpportunities[0].lastActionDate : <p>---</p> : <p>---</p>}</td>
                                        </tr>
                                    </>
                                    : ""
                                    }
                                {SapOpportunities.length > 0 ?
                                    <>
                                        <tr>
                                            <td>Engagement Created On</td>
                                            <td> {SapOpportunities.length > 0 ? SapOpportunities[0].sapStatus == 1 ? SapOpportunities[0].createdOn : <p>---</p> : <p>---</p>}</td>
                                            <td></td>
                                        </tr>
                                    </>
                                    : ""
                                }
                                {(drbOpportunities.length) > 0 ?
                                    <>
                                        <tr>
                                            <td>DRB</td>
                                            <td>{drbOpportunities.length > 0 ? drbOpportunities[0].createdOn : <p>---</p>}</td>
                                            <td>{drbOpportunities.length > 0 ? drbOpportunities[0].drbStatus == 1 ? drbOpportunities[0].modifiedOn : <p>---</p> : <p>---</p>}</td>
                                        </tr>
                                    </> : ""
                                }
                                {(edsOpportunities.length) > 0 ?
                                    <>
                                        <tr>
                                            <td>EDS</td>
                                            <td>{edsOpportunities.length > 0 ? edsOpportunities[edsOpportunities.length - 1].createdOn : <p>---</p>}</td>
                                            <td></td>
                                            {/*<td>{edsOpportunities.length > 0 ? edsOpportunities[0].drbStatus == 1 ? edsOpportunities[0].modifiedOn : <p>---</p> : <p>---</p>}</td>*/}
                                        </tr>
                                    </> : ""
                                }
                                {(crpOpportunities.length) > 0 ?
                                    <>
                                        <tr>
                                            <td>CRP</td>
                                            <td>{crpOpportunities.length > 0 ? crpOpportunities[0].createdOn : <p>---</p>}</td>
                                            <td>{crpOpportunities.length > 0 ? crpOpportunities[0].crpStatus == 1 ? crpOpportunities[0].modifiedOn : <p>---</p> : <p>---</p>}</td>
                                        </tr>
                                    </> : ""
                                }
                            </tbody>
                        </table>
                    </div>


                </div>
                {pmsBusiness == 'Consulting' ?
                    <div>
                        <div className='offline-process desktop-view'>
                            <div href="#" className="hexagon-btn ribbon-outset border">
                                PCOE
                                <div className='offline-process-dot'></div>
                            </div>
                            <div href="#" className="hexagon-btn ribbon-outset border">SRB<div className='offline-process-dot'></div></div>
                            <div className='offline-process-heading'>
                                Offline <br /> Proccess
                            </div>
                        </div>
                        <p className='notes'>
                            * This flow does not cover all offline process during O2E journey.
                        </p> </div> : <p></p>}
                <div className='detail-page-status'>
                    <ul className="status-list">
                        <li className="status-item ">
                            <div className="completed-icon-status status-item-icon">C</div>
                            Completed
                        </li>
                        <li className="status-item ">
                            <div className="progress-icon-status status-item-icon">P</div>
                            In Progress
                        </li>
                        <li className="status-item">
                            <div className="not-started-icon-status status-item-icon">NS</div>
                            Not Started
                        </li>
                        <li className="status-item">
                            <div className="not-required-icon-status status-item-icon">NR</div>
                            Not Required
                        </li>
                        <li className="status-item">
                            <div className="rejected-icon-status status-item-icon">R</div>
                            Rejected
                        </li>
                        <li className="status-item">
                            <div className="closed-lost-icon-status status-item-icon">CL</div>
                            Closed Lost
                        </li>
                    </ul></div>
            </div>



            {isLoading && <div className="preloader">
                <div className="loader"><div></div><div></div><div></div></div>
            </div>}

            {/* Timeline Overview modal end */}

            {/* More Info Modal start EDS */}
            <div class="modal moreInfoModal" aria-labelledby="moreEDSInformation" ref={edsModalRef} tabIndex="-1" aria-modal="true" aria-hidden="true" role='dialog'  id="moreEDSInfoModal" >
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">

                        <div class="modal-header">
                            <h2 id="moreEDSInformation" class="modal-title">EDS Details</h2>
                            <button type="button" aria-label="Close" class="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div class="modal-body">
                            {edsOpportunities.length > 0 ?

                                edsOpportunities.map((Opp, i) => (

                                    <div key={i} className='know-more-list'>
                                        <ul className='sap-know-more-list'>
                                            <li className="know-more-item">
                                                <div className="label">Engagement Code:</div>
                                                <div className="label-value">{Opp.engagementNumber}</div>
                                            </li>
                                            <li className="know-more-item">
                                                <div className="label">Status:</div>
                                                <div className="label-value">{Opp.status}</div>
                                            </li>
                                            <li className="know-more-item">
                                                <div className="label">Engagement Created Date:</div>
                                                <div className="label-value">{Opp.createdOn}</div>
                                            </li>
                                        </ul>
                                    </div>

                                )) : <div></div>}
                        </div>
                    </div>
                </div>
            </div>
            {/* More Info Modal end */}


            {/* More Info Modal start CRP */}
            <div class="modal moreInfoModal" aria-labelledby="moreCRPInformation" ref={crpModalRef} tabIndex="-1" aria-modal="true" aria-hidden="true" role='dialog' id="moreCRPInfoModal">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">

                        <div class="modal-header">
                            <h2 id="moreCRPInformation"  class="modal-title">CRP Details</h2>
                            <button type="button" onClick={crpCloseModal} class="close" aria-label="Close" data-dismiss="modal">&times;</button>
                        </div>
                        <div class="modal-body">
                            {crpOpportunities.length > 0 ?

                                crpOpportunities.map((Opp, i) => (

                                    <div key={i} className='know-more-list'>
                                        <ul className='sap-know-more-list'>
                                            <li className="know-more-item">
                                                <div className="label">Request Id:</div>
                                                <div className="label-value">{Opp.requestId}</div>
                                            </li>
                                            {/*<div className="know-more-item">*/}
                                            {/*    <div className="label">Engagement Code:</div>*/}
                                            {/*    <div className="label-value">{Opp.engagementNumber}</div>*/}
                                            {/*</div>*/}
                                            <li className="know-more-item">
                                                <div className="label">Status:</div>
                                                <div className="label-value">{Opp.engStatusText}</div>
                                            </li>
                                            <li className="know-more-item">
                                                <div className="label">Pending With:</div>
                                                <div className="label-value">{Opp.pendingWith}</div>
                                            </li>
                                            <li className="know-more-item">
                                                <div className="label">Created On:</div>
                                                <div className="label-value">{Opp.createdOn}</div>
                                            </li>

                                        </ul>
                                    </div>

                                )) : <div></div>}
                        </div>
                    </div>
                </div>
            </div>
            {/* More Info Modal end */}


            {/* More Info Modal start SAP */}
            <div class="modal moreInfoModal" aria-labelledby="moreSAPInformation"  ref={sapModalRef} tabIndex="-1" aria-modal="true" aria-hidden="true" role='dialog' id="moreSAPInfoModal">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">

                        <div class="modal-header">
                            <h2 id="moreSAPInformation" class="modal-title">SAP Details</h2>
                            <button type="button" onClick={sapCloseModal} class="close" aria-label="Close" data-dismiss="modal">&times;</button>
                        </div>
                        <div class="modal-body">
                            {SapOpportunities.map((Opp, i) => (

                                <div key={i} className='know-more-list'>
                                    <ul className='sap-know-more-list'>
                                        <li className="know-more-item">
                                            <div className="label">Engagement Code:</div>
                                            <div className="label-value">{Opp.eng_Code}</div>
                                        </li>
                                        <li className="know-more-item">
                                            <div className="label">Engagement Partner:</div>
                                            <div className="label-value">{Opp.eng_Partner}</div>
                                        </li>
                                        <li className="know-more-item">
                                            <div className="label">Created On:</div>
                                            <div className="label-value">{Opp.createdOn}</div>
                                        </li>
                                        <li className="know-more-item">
                                            <div className="label">Engagement Start Date:</div>
                                            <div className="label-value">{Opp.eng_StartDate}</div>
                                        </li>
                                        <li className="know-more-item">
                                            <div className="label">Engagement End Date:</div>
                                            <div className="label-value">{Opp.eng_Enddate}</div>
                                        </li>
                                    </ul>
                                </div>

                            ))} : <div></div>
                        </div>
                    </div>
                </div>
            </div>
            {/* More Info Modal end */}

            {/* More Info Modal start ECM */}
            <div class="modal moreInfoModal" aria-labelledby="moreECMInformation" ref={ecmModalRef} tabIndex="-1" aria-modal="true" aria-hidden="true" role='dialog' id="moreECMInfoModal">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">

                        <div class="modal-header">
                            <h2 id="moreECMInformation" class="modal-title">ECM Details</h2>
                            <button type="button" onClick={ecmCloseModal} class="close" aria-label="Close" data-dismiss="modal">&times;</button>
                        </div>
                        <div class="modal-body">
                            {ecmOpportunities.map((Opp, i) => (
                                <ul key={i} className='know-more-list'>
                                    <li className="know-more-item-header">
                                        Document Type :  {Opp.documentType}
                                    </li>
                                    <li className="know-more-item">
                                        <div className="label">Status:</div>
                                        <div className="label-value">{Opp.status}</div>
                                    </li>
                                    <li className="know-more-item">
                                        <div className="label">To be actioned by:</div>
                                        <div className="label-value">{Opp.toBeActionBy}</div>
                                    </li>
                                    <li className="know-more-item">
                                        <div className="label">Last Actioned By:</div>
                                        <div className="label-value">{Opp.lastActionedBy}</div>
                                    </li>
                                    <li className="know-more-item">
                                        <div className="label">Last Action Date:</div>
                                        <div className="label-value">{Opp.lastActionDate}</div>
                                    </li>
                                    <li className="know-more-item">
                                        <div className="label">Days since Last Action:</div>
                                        <div className="label-value">{Opp.daysSinceLastAction}</div>
                                    </li>

                                </ul>
                            ))} : <div></div>
                        </div>
                    </div>
                </div>
            </div>
            {/* More Info Modal end */}

        </>
    )
}

export default OppurtunityItem;
