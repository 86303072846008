import React, { createContext, useContext, useState, useEffect } from 'react'

const StoreContext = createContext();

export const StoreProvider = ({children}) => {

    const [searchText, setSearchText] = useState(() => {
      const savedState1 = localStorage.getItem('searchText');
      if(savedState1 !== "undefined" && savedState1 !== null){
        return JSON.parse(savedState1)
      } else {
        return ""
      }
    });

    const [startSearch, setStartSearch] = useState(() => {
      const savedState2 = localStorage.getItem('startSearch');
      if(savedState2 !== "undefined" && savedState2 !== null){
        return JSON.parse(savedState2)
      } else {
        return false
      }
    });

    const [isClearOn, setIsClearOn] = useState(() => {
      const savedState3 = localStorage.getItem('isClearOn');
      if(savedState3 !== "undefined" && savedState3 !== null){
        return JSON.parse(savedState3)
      } else {
        return false
      }
    });

    useEffect(() => {
      localStorage.setItem('searchText', JSON.stringify(searchText));
    }, [searchText]);

    useEffect(() => {
      localStorage.setItem('startSearch', JSON.stringify(startSearch));
    }, [startSearch]);

    useEffect(() => {
      localStorage.setItem('isClearOn', JSON.stringify(isClearOn));
    }, [isClearOn]);

  return (
    <StoreContext.Provider value={{ searchText, setSearchText, startSearch, setStartSearch, isClearOn, setIsClearOn }}>
      {children}
    </StoreContext.Provider>
  )
}

export const useStore = () => {
    return useContext(StoreContext);
};